import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';

export interface FileRepoResponse {
  name: string;
  directory: boolean;
  content?: string;
  children: [];
}

@Injectable({ providedIn: 'root' })
export class FileViewService {
  mimeMap: Map<string, string> = new Map();
  sourceMap: Map<string, string> = new Map<string, string>();
  directoryMap: Map<string, FileRepoResponse[]> = new Map();

  constructor(private http: HttpClient) {
    this.mimeMap.set('js', 'text/javascript');
    this.mimeMap.set('ts', 'text/typescript');
    this.mimeMap.set('json', 'text/javascript');
    this.mimeMap.set('css', 'text/css');
    this.mimeMap.set('html', 'text/html');
    this.mimeMap.set('scss', 'text/css');
    this.mimeMap.set('c', 'text/x-csrc');
    this.mimeMap.set('cpp', 'text/x-c++src');
    this.mimeMap.set('.cs', 'text/csharp');
    this.mimeMap.set('.java', 'text/x-java');
    this.mimeMap.set('.xml', 'application/xml');
  }

  getMime(fileName: string): string | undefined {
    let extension = this.getExtension(fileName);
    if (this.mimeMap.has(extension)) {
      return this.mimeMap.get(extension);
    }
  }

  getExtension(file: string): string {
    let index = file.lastIndexOf('.');
    return file.substr(index + 1, file.length);
  }

  fetchCommits(projectId: string): Observable<any> {
    const url: string = `projects/${projectId}/commits`;
    return this.http.get(ConfigService.getApiURL(url));
  }

  fetchFiles(repo: string, isDirectory: boolean = true, path: string): Observable<FileRepoResponse[]> {
    let params: HttpParams = new HttpParams();
    let url = ConfigService.getAuxURL(`/repo/${repo}/files`);

    // if (path) {
    //   params = params.append('path', path);
    // }

    // if (isDirectory != null) {
    //   params = params.append('isDirectory', String(isDirectory));
    // }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        if (res) {
          this.updateFileMaps(path, res);
        }
        return res;
      })
    ) as Observable<any>;
  }

  /**
   * @param parentPath
   * @param files
   */
  updateFileMaps(parentPath, files: FileRepoResponse[]): void {
    // Set directory map to lookup files.
    this.directoryMap.set(parentPath, files);

    // Set source map to lookup content.
    for (let file of files) {
      let path = parentPath ? parentPath + '/' + file.name : file.name;
      if (!this.sourceMap.has(path)) {
        // let content: string = atob(file.content);
        this.sourceMap.set(path, file.content);
      }
    }
  }

  /**
   * Fetch contents of a file in a repo. Will fetch from source map if available.
   * @param fileName
   * @param repo
   * @returns
   */
  fetchFileContents(fileName: string, repo: string): Observable<any> {
    if (!this.sourceMap.has(fileName)) {
      this.fetchFiles(repo, false, '').subscribe(res => {
        for (let key of this.sourceMap.keys()) {
          if (key === fileName) {
            return of(this.sourceMap.get(key));
          }
        }
      });
    }
    return of(this.sourceMap.get(fileName));
  }
}
