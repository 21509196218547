// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: 'false',
  baseAuxUrl: '',
  baseUiUrl: '/',
  secureProtocol: window['env']['secureProtocol'],
  domainAux: window['env']['domainAux'] || '192.168.1.50:8081',
  domainAPI: window['env']['domainAPI'] || '192.168.1.50:8081/pulsar',
  domainUI: window['env']['domainUI'] || 'localhost:4419'
};
